import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Icon,
  Link,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react"
import { FocusableElement } from "@chakra-ui/utils"
import SocialLinks from "@components/Footer/SocialLinks"
import NavLink from "@components/Navbar/NavLink"
import SvgLogo from "@public/images/logo.ae.svg"
import { navLinks } from "@utils/common"
import getConfig from "next/config"
import NextLink from "next/link"
import { RefObject } from "react"

type NavDrawerProps = {
  onClose: () => void
  isOpen: boolean
  finalFocusRef: RefObject<FocusableElement>
}
const NavDrawer = ({ onClose, isOpen, finalFocusRef }: NavDrawerProps) => {
  const logoColor = useColorModeValue("black", "white")
  const { publicRuntimeConfig } = getConfig()
  const version = publicRuntimeConfig?.version

  return (
    <Drawer
      onClose={onClose}
      isOpen={isOpen}
      finalFocusRef={finalFocusRef}
      placement={"left"}
    >
      <DrawerOverlay />
      <DrawerContent bgColor={useColorModeValue("white", "black")}>
        <DrawerCloseButton />
        <DrawerHeader>
          <Flex justifyContent={"center"}>
            <Link
              as={NextLink}
              href={"/"}
              title={"Home"}
            >
              <Icon
                as={SvgLogo}
                alt={"gzb.ae Logo"}
                color={logoColor}
                w={28}
                h={12}
              />
            </Link>
          </Flex>
        </DrawerHeader>

        <DrawerBody>
          <VStack
            spacing={6}
            alignItems={"center"}
            mt={4}
          >
            {navLinks.map((link, index) => (
              <NavLink
                key={index}
                href={link.href}
                onClick={onClose}
              >
                {link.label}
              </NavLink>
            ))}
          </VStack>
        </DrawerBody>

        <DrawerFooter
          justifyContent={"center"}
          fontSize={"xs"}
        >
          <VStack>
            <SocialLinks />
            <Box>{version && `v.${version}`}</Box>
          </VStack>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}

export default NavDrawer
