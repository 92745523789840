import { Box, BoxProps, Container, useColorModeValue } from "@chakra-ui/react"
import Footer from "@components/Footer/Footer"
import Navbar from "@components/Navbar/Navbar"

const DefaultSections = ({ children, ...props }: BoxProps) => {
  return (
    <Box
      bgColor={useColorModeValue("white", "black")}
      {...props}
    >
      <Navbar />
      <Container maxW={"container.lg"}>{children}</Container>
      <Footer />
    </Box>
  )
}

export default DefaultSections
