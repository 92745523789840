import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  HStack,
  Icon,
  Input,
} from "@chakra-ui/react"
import { useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { FaRegCheckCircle } from "react-icons/fa"

const NewsletterSignup = () => {
  const [formSuccess, setFormSuccess] = useState<boolean>(false)
  const [formLoading, setFormLoading] = useState<boolean>(false)

  const defaultValues = {
    email: "",
  }

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<typeof defaultValues>({
    defaultValues: defaultValues,
  })

  const onSubmit = async (values: typeof defaultValues) => {
    setFormLoading(true)
    await fetch("/api/newsletter", {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
      },
    }).then(() => {
      setFormSuccess(true)
      setFormLoading(false)
    })
  }

  if (formSuccess) {
    return (
      <Flex
        width={"full"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Icon as={FaRegCheckCircle} />
        <Box
          ms={1}
          mt={1}
        >
          {"Thank you for subscribing!"}
        </Box>
      </Flex>
    )
  }

  return (
    <Box
      as={"form"}
      onSubmit={handleSubmit(onSubmit)}
    >
      <HStack
        spacing={0}
        alignItems={"flex-end"}
      >
        <FormControl isInvalid={!!errors.email}>
          <FormErrorMessage>
            {errors.email && errors.email.message}
          </FormErrorMessage>
          <Controller
            control={control}
            name={"email"}
            rules={{
              required: "Email is required",
              pattern: {
                value:
                  // eslint-disable-next-line no-control-regex
                  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
                message: "Invalid email address",
              },
            }}
            render={({ field: { ref, ...restField } }) => (
              <Input
                id={"email"}
                placeholder={"Email Address"}
                type={"email"}
                size={"sm"}
                color={"black"}
                bgColor={"white"}
                borderLeftRadius={8}
                _placeholder={{ color: "gray.300" }}
                ref={ref}
                isDisabled={formSuccess}
                {...restField}
              />
            )}
          />
        </FormControl>
        <Button
          type={"submit"}
          colorScheme={"yellow"}
          size={"sm"}
          px={6}
          borderLeftRadius={0}
          isLoading={isSubmitting || formLoading}
          isDisabled={isSubmitting || formLoading}
        >
          {"Subscribe"}
        </Button>
      </HStack>
    </Box>
  )
}

export default NewsletterSignup
