import { Link, LinkProps } from "@chakra-ui/react"
import NextLink from "next/link"

const NavLink = ({ children, ...props }: LinkProps) => {
  return (
    <Link
      as={NextLink}
      fontWeight={500}
      fontSize={"md"}
      {...props}
    >
      {children}
    </Link>
  )
}

export default NavLink
