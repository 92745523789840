import { Box, Icon, IconButton } from "@chakra-ui/react"
import { useEnchant } from "@common/Enchant"
import { MdOutlineSupportAgent } from "react-icons/md"

const EnchantChat = () => {
  const { chatAvailable } = useEnchant()
  if (chatAvailable)
    return (
      <IconButton
        aria-label={"online chat"}
        icon={
          <Icon
            as={MdOutlineSupportAgent}
            w={{ base: 6, md: 10 }}
            h={{ base: 6, md: 10 }}
          />
        }
        bgColor={"yellow.400"}
        color={"black"}
        isRound
        w={{ base: 10, md: 16 }}
        h={{ base: 10, md: 16 }}
        onClick={() => window.enchant?.messenger?.open()}
        position={"fixed"}
        bottom={{ base: 6, md: 8 }}
        right={{ base: 4, md: 6 }}
        shadow={"lg"}
      />
    )

  return <Box />
}

export default EnchantChat
