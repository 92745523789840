import {
  Box,
  Container,
  Flex,
  FlexProps,
  HStack,
  Icon,
  IconButton,
  Link,
  useColorMode,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react"
import { hasSnipcart, useSnipcart } from "@common/Snipcart"
import NavDrawer from "@components/Navbar/Drawer"
import SvgLogo from "@public/images/logo.ae.svg"
import { useRouter } from "next/compat/router"
import NextLink from "next/link"
import { useRef } from "react"
import { FaShoppingCart, FaUserCircle } from "react-icons/fa"
import { GiHamburgerMenu } from "react-icons/gi"
import { MdOutlineDarkMode, MdOutlineLightMode } from "react-icons/md"
import { useWindowScrollPosition } from "rooks"

const Navbar = ({ ...props }: FlexProps) => {
  const { cartQuantity } = useSnipcart()
  const { colorMode, toggleColorMode } = useColorMode()
  const logoColor = useColorModeValue("black", "white")

  const handleCartOpen = () => {
    hasSnipcart() && window.Snipcart?.api.theme.cart.open()
  }

  // Drawer
  const { isOpen, onOpen, onClose } = useDisclosure()
  const menuBtnRef = useRef<HTMLButtonElement>(null)
  const { scrollY } = useWindowScrollPosition()
  const router = useRouter()
  const floatCart = router?.route === "/p/[...slug]" && scrollY > 80
  return (
    <Container maxW={"container.lg"}>
      <Flex
        justifyContent={"space-between"}
        alignItems={"center"}
        as={"nav"}
        py={2}
        {...props}
      >
        <HStack spacing={2}>
          <IconButton
            aria-label={"Open Menu"}
            variant={"ghost"}
            icon={<GiHamburgerMenu />}
            ref={menuBtnRef}
            onClick={onOpen}
            size={"sm"}
            _hover={{
              bgColor: "transparent",
            }}
          />
          <IconButton
            variant={"ghost"}
            title={"Toggle Dark Mode"}
            aria-label={"toggle color mode"}
            onClick={toggleColorMode}
            size={"sm"}
            icon={
              colorMode === "light" ? (
                <MdOutlineDarkMode />
              ) : (
                <MdOutlineLightMode />
              )
            }
            _hover={{
              bgColor: "transparent",
            }}
          />
        </HStack>

        <Link
          as={NextLink}
          href={"/"}
          title={"Home"}
        >
          <Icon
            as={SvgLogo}
            alt={"gzb.ae Logo"}
            color={logoColor}
            w={28}
            h={12}
          />
        </Link>

        <HStack spacing={2}>
          <IconButton
            aria-label={"User"}
            variant={"ghost"}
            icon={<FaUserCircle />}
            className={"snipcart-customer-signin"}
            size={"sm"}
            _hover={{
              bgColor: "transparent",
            }}
          />
          <Flex
            justifyContent={"center"}
            alignItems={"center"}
            position={floatCart ? "fixed" : "relative"}
            zIndex={10}
            bgColor={floatCart ? "yellow.400" : "transparent"}
            borderRadius={"full"}
            shadow={floatCart ? "lg" : "none"}
            transform={
              floatCart ? "translateY(100%) translateX(-12px)" : "none"
            }
            transitionProperty={"background-color, box-shadow"}
            transitionDuration={"0.5s"}
            transitionTimingFunction={"ease-in-out"}
            p={1}
          >
            <Box
              top={0}
              left={0}
              width={"full"}
              fontSize={"xs"}
              position={"absolute"}
              textAlign={"center"}
            >
              {cartQuantity > 0 && cartQuantity}
            </Box>
            <IconButton
              aria-label={"Cart"}
              variant={"ghost"}
              icon={<FaShoppingCart />}
              onClick={handleCartOpen}
              size={"sm"}
              _hover={{
                bgColor: "transparent",
              }}
            />
          </Flex>
        </HStack>
      </Flex>
      <NavDrawer
        isOpen={isOpen}
        onClose={onClose}
        finalFocusRef={menuBtnRef}
      />
    </Container>
  )
}

export default Navbar
