import { HStack, IconButton, Link } from "@chakra-ui/react"
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaTiktok,
  FaTwitter,
} from "react-icons/fa"

const SocialLinks = () => {
  return (
    <HStack spacing={0}>
      <IconButton
        as={Link}
        href={"https://www.facebook.com/www.gzb.ae"}
        isExternal
        icon={<FaFacebook />}
        aria-label={"Facebook"}
        variant={"ghost"}
        _hover={{ bgColor: "transparent" }}
      />
      <IconButton
        as={Link}
        href={"https://www.instagram.com/gzb_ae/"}
        isExternal
        icon={<FaInstagram />}
        aria-label={"Instagram"}
        variant={"ghost"}
        _hover={{ bgColor: "transparent" }}
      />
      <IconButton
        as={Link}
        href={"https://twitter.com/gzb_ae"}
        isExternal
        icon={<FaTwitter />}
        aria-label={"Twitter"}
        variant={"ghost"}
        _hover={{ bgColor: "transparent" }}
      />
      <IconButton
        as={Link}
        href={"https://www.linkedin.com/company/gzb-ae/"}
        isExternal
        icon={<FaLinkedin />}
        aria-label={"LinkedIn"}
        variant={"ghost"}
        _hover={{ bgColor: "transparent" }}
      />
      <IconButton
        as={Link}
        href={"https://www.tiktok.com/@gzb.ae"}
        isExternal
        icon={<FaTiktok />}
        aria-label={"TikTok"}
        variant={"ghost"}
        _hover={{ bgColor: "transparent" }}
      />
    </HStack>
  )
}

export default SocialLinks
