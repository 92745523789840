import { Link, LinkProps } from "@chakra-ui/react"
import NextLink from "next/link"

const FooterLink = ({ children, ...props }: LinkProps) => {
  return (
    <Link
      as={NextLink}
      fontWeight={400}
      fontSize={"sm"}
      p={1.5}
      {...props}
    >
      {children}
    </Link>
  )
}

export default FooterLink
