import {
  Box,
  Container,
  Divider,
  Flex,
  HStack,
  Icon,
  Link,
  SimpleGrid,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react"
import EnchantChat from "@components/Footer/EnchantChat"
import FooterLink from "@components/Footer/FooterLink"
import NewsletterSignup from "@components/Footer/NewsletterSignup"
import SocialLinks from "@components/Footer/SocialLinks"
import SvgLogo from "@public/images/logo.ae.svg"
import { navLinks } from "@utils/common"
import NextLink from "next/link"
import { FaCcMastercard, FaCcVisa } from "react-icons/fa"
import { HiMail } from "react-icons/hi"
import { HiPhone } from "react-icons/hi2"
import { IoCashOutline } from "react-icons/io5"
import { MdOutlineSupportAgent } from "react-icons/md"

const Footer = () => {
  const logoColor = useColorModeValue("black", "white")

  return (
    <Flex
      as={"footer"}
      justifyContent={"center"}
      alignItems={"center"}
      bgColor={useColorModeValue("gray.100", "gray.700")}
      w={"full"}
      py={4}
      fontSize={"sm"}
      flexDirection={"column"}
      mt={10}
      position={"relative"}
    >
      <EnchantChat />
      <Container maxW={"container.lg"}>
        <VStack
          borderBottom={"1px solid"}
          borderColor={"gray.300"}
          pb={4}
          width={"80%"}
          alignItems={"center"}
          justifyContent={"center"}
          spacing={2}
          w={"full"}
        >
          <Link
            as={NextLink}
            href={"/"}
            title={"Home"}
          >
            <Icon
              as={SvgLogo}
              alt={"gzb.ae Logo"}
              color={logoColor}
              w={28}
              h={12}
            />
          </Link>

          <Divider borderColor={"gray.300"} />

          <Flex
            align={"center"}
            justify={"center"}
            wrap={"wrap"}
            pb={6}
          >
            <HStack
              align={"center"}
              justify={"center"}
              w={"full"}
              p={4}
            >
              <Icon
                as={MdOutlineSupportAgent}
                w={8}
                h={8}
              />
              <Box fontSize={"lg"}>{"Need Help?"}</Box>
            </HStack>

            <HStack
              align={"center"}
              me={4}
            >
              <Icon
                as={HiPhone}
                w={4}
                h={4}
              />
              <Link href={"tel:+97167310280"}>{"+971 6 731 0280"}</Link>
            </HStack>

            <Flex align={"center"}>
              <Icon
                as={HiMail}
                w={6}
                h={6}
                me={1}
              />
              <Link href={"mailto:support@gzb.ae"}>{"support@gzb.ae"}</Link>
            </Flex>
          </Flex>

          <Divider borderColor={"gray.300"} />

          <SimpleGrid
            gridTemplateColumns={{
              base: "repeat(2, 1fr)",
              md: "repeat(3, 1fr)",
            }}
            gridTemplateRows={{
              base: "repeat(5, auto)",
              md: "repeat(3, auto)",
            }}
            gridAutoFlow={"column"}
            spacingX={10}
            spacingY={2}
            py={4}
          >
            {navLinks.map((link, index) => (
              <FooterLink
                key={index}
                href={link.href}
              >
                {link.label}
              </FooterLink>
            ))}
          </SimpleGrid>

          <Divider borderColor={"gray.300"} />

          <SocialLinks />

          <NewsletterSignup />
        </VStack>
        <VStack
          mt={4}
          w={"full"}
        >
          <HStack>
            <Icon
              title={"Visa"}
              as={FaCcVisa}
              w={8}
              h={8}
            />
            <Icon
              title={"Mastercard"}
              as={FaCcMastercard}
              w={8}
              h={8}
            />
            <Icon
              title={"Cash on Delivery"}
              as={IoCashOutline}
              w={8}
              h={8}
            />
          </HStack>
          <Text fontSize={"xs"}>
            {"© "}
            {new Date().getFullYear()}
            {" White Lion General Trading LLC. All rights reserved."}
          </Text>
        </VStack>
      </Container>
    </Flex>
  )
}

export default Footer
